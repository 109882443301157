.footer-container {
    background-color: #181818;
    color: white;
    text-align: center;
    padding: 20px 0;
    font-family: "Arial", sans-serif;
  }
  
  .footer-logo img {
    width: 120px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .footer-nav {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 15px;
  }
  
  .footer-nav a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .footer-nav a:hover {
    color: #ff4c4c;
  }
  
  .footer-social {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-bottom: 10px;
  }
  
  .footer-social img {
    width: 35px;
    height: 35px;
    transition: transform 0.3s ease;
  }
  
  .footer-social img:hover {
    transform: scale(1.1);
  }
  
  .footer-rights {
    font-size: 14px;
    opacity: 0.7;
  }
  
.main-page {
    font-family: Arial, sans-serif;
    background: #1c1c1c;
    color: white;
    text-align: center;
    padding: 20px;
    position: relative;
  
    /* Add background image */
    background-image: url('../../assets/background.jpg'); /* Replace with your image path */
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
  }
  
  .hero-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 60px 20px;
    color: white;
    font-family: 'Arial', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
}

.logo {
    width: 120px;
    margin-bottom: 20px;
}

h1 {
    font-size: 36px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.hero-section::after {
    content: '';
    width: 80%;
    height: 2px;
    background-color: #a63d3d; /* Red accent line */
    position: absolute;
    bottom: 10px;
}

  
  .rental-service {
    font-size: 22px;
    margin: 20px 0;
  }
  
  .highlight {
    color: red;
  }
  
  .about-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0;
    padding: 20px;
    background-color: #1b1b1b;
    color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  }
  
  .about-text {
    flex: 1;
    max-width: 500px;
    padding: 20px;
    text-align: left;
  }
  
  .about-text h3 {
    color: #e63946;
    font-size: 28px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  
  .about-text p {
    line-height: 1.8;
    font-size: 16px;
  }
  
  .about-text .highlight {
    color: #e63946;
    font-weight: bold;
  }
  
  .about-image {
    flex: 1;
    max-width: 400px;
    width: 100%;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  }
  
  
  .motorpark {
    margin: 40px 0;
    padding: 20px;
    text-align: center;
    background-color: #1b1b1b;
    color: #fff;
    border-radius: 12px;
  }
  
  .motorpark h3 {
    color: #e63946;
    font-size: 28px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  
  .bike-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .bike-card {
    background: #f9f9f9;
    color: #333;
    padding: 15px;
    border-radius: 12px;
    width: 220px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    text-align: left;
  }
  
  .bike-card img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .bike-card h4 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #e63946;
  }
  
  .bike-card p {
    font-size: 14px;
    line-height: 1.6;
  }
  
  .motorpark-info {
    margin: 20px 0;
    font-size: 16px;
    line-height: 1.8;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .social-icons img {
    width: 40px;
    height: 40px;
    filter: brightness(0) invert(1);
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .social-icons img:hover {
    transform: scale(1.1);
  }
  
  .terms-section {
    margin: 40px 0;
    padding: 20px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    background-color: #1b1b1b;
    color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
    font-family: "Arial", sans-serif;
  }
  
  .terms-section h3 {
    color: #e63946;
    font-size: 28px;
    margin-bottom: 20px;
    text-transform: uppercase;
    text-align: center;
  }
  
  .terms-section ul {
    list-style: none;
    padding: 0;
  }
  
  .terms-section li {
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 1.6;
  }
  
  .terms-section li strong {
    color: #e63946;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .terms-section {
      padding: 15px;
    }
  
    .terms-section h3 {
      font-size: 24px;
    }
  
    .terms-section li {
      font-size: 14px;
    }
  }
  
  
  .contact-section {
    background-color: #1b1b1b;
    color: #fff;
    padding: 40px;
    max-width: 900px;
    margin: 20px auto;
    font-family: "Arial", sans-serif;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  }
  
  .contact-title {
    font-size: 32px;
    color: #e63946;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 30px;
    text-align: center;
  }
  
  p {
    font-size: 18px;
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  .highlight {
    color: #e63946; /* Red accent color */
    font-weight: bold;
  }
  
  .contact-link {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  .contact-link:hover {
    color: #e63946;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .social-icons img {
    width: 40px;
    transition: transform 0.3s, filter 0.3s;
    filter: grayscale(100%);
  }
  
  .social-icons img:hover {
    transform: scale(1.1);
    filter: grayscale(0);
  }
  
  .map-container {
    margin-top: 30px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  }
  
  @media (max-width: 768px) {
    .contact-section {
      padding: 20px;
    }
  
    .contact-title {
      font-size: 24px;
    }
  
    p {
      font-size: 16px;
    }
  
    .social-icons img {
      width: 30px;
    }
  }
  
/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
  }
  
  /* Header Container */
  .header-container {
    background-color: #1e1e1e; /* fallback color */
    color: #fff;
    padding: 10px 20px;
    text-align: center;
    position: relative;
  
    /* Add background image */
    background-image: url('../../assets/background.jpg'); /* Replace with your image path */
    background-size: cover; /* Ensures the image covers the entire container */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    height: 200px; /* Adjust the height of the header as needed */
  }
  
  
  /* Header Content */
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .logo-section {
    flex: 1;
    text-align: left;
  }
  
  .logo p {
    font-size: 12px;
    letter-spacing: 1px;
  }
  
  .logo h1 {
    font-size: 28px;
    font-weight: bold;
    line-height: 1;
  }
  
  .logo h1 span {
    color: #e63946; /* Highlighted text */
  }
  
  .logo .locations {
    font-size: 12px;
    color: #aaa;
  }
  
  /* Contact Section */
  .contact-section {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .contact-section i {
    font-size: 20px;
  }
  
  .phone-number {
    font-size: 18px;
  }
  
  /* Social Icons */
  .social-section {
    display: flex;
    gap: 15px;
  }
  
  .social-section i {
    font-size: 20px;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .social-section i:hover {
    color: #e63946;
  }
  
  /* Bottom Navigation */
  .header-nav {
    margin-top: 10px;
    border-top: 2px solid #e63946;
    padding: 10px 0;
  }
  
  .header-nav a {
    text-decoration: none;
    color: #fff;
    font-size: 14px;
    margin: 0 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: color 0.3s ease;
  }
  
  .header-nav a:hover {
    color: #e63946;
  }
  